import { NestleLogo } from '@components/home/icons'
import { Image } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { projects as RicoreProjects } from '@components/ricore/data/projects'
import { projects as PurinaProjects } from '@components/purina/data/projects'
import { projects as NestleProjects } from '@components/nestle/data/projects'
import React from 'react'

const nestleLogo = <NestleLogo color="inherit" w={'inherit'} h={'25px'} />
const ricoreLogo = <Image src={s3ImageURL('ricore-logo.png')} />
const purinaLogo = <Image src={s3ImageURL('purina-logo-friskies.png')} />

export const Agris = [
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[0].title,
    slug: PurinaProjects[0].slug,
    fullname: PurinaProjects[0].fullname,
    location: PurinaProjects[0].place,
    description: PurinaProjects[0].text,
    image: PurinaProjects[0].image_alt,
    order: 0,
  },
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[1].title,
    slug: PurinaProjects[1].slug,
    fullname: PurinaProjects[1].fullname,
    location: PurinaProjects[1].place,
    description: PurinaProjects[1].text,
    image: PurinaProjects[1].image_alt,
    order: 1,
  },
  {
    logo: purinaLogo,
    projectTitle: PurinaProjects[2].title,
    slug: PurinaProjects[2].slug,
    fullname: PurinaProjects[2].fullname,
    location: PurinaProjects[2].place,
    description: PurinaProjects[2].text,
    image: PurinaProjects[2].image_alt,
    order: 2,
  },
  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[0].title,
    slug: RicoreProjects[0].slug,
    fullname: RicoreProjects[0].fullname,
    location: RicoreProjects[0].place,
    description: RicoreProjects[0].text,
    image: RicoreProjects[0].image_alt ? RicoreProjects[0].image_alt : RicoreProjects[0].image,
    order: 3,
  },

  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[1].title,
    slug: RicoreProjects[1].slug,
    fullname: RicoreProjects[1].fullname,
    location: RicoreProjects[1].place,
    description: RicoreProjects[1].text,
    image: RicoreProjects[1].image_alt ? RicoreProjects[1].image_alt : RicoreProjects[1].image,
    order: 4,
  },
  {
    logo: ricoreLogo,
    projectTitle: RicoreProjects[2].title,
    slug: RicoreProjects[2].slug,
    fullname: RicoreProjects[2].fullname,
    location: RicoreProjects[2].place,
    description: RicoreProjects[2].text,
    image: RicoreProjects[2].image_alt ? RicoreProjects[2].image_alt : RicoreProjects[2].image,
    order: 5,
  },
  {
    logo: nestleLogo,
    projectTitle: NestleProjects[1].title2,
    slug: NestleProjects[1].slug,
    fullname: NestleProjects[1].username,
    location: NestleProjects[1].place,
    description: NestleProjects[1].description,
    image: NestleProjects[1].image,
  },
  {
    logo: nestleLogo,
    projectTitle: NestleProjects[0].title2,
    slug: NestleProjects[0].slug,
    fullname: NestleProjects[0].username,
    location: NestleProjects[0].place,
    description: NestleProjects[0].description,
    image: NestleProjects[0].image,
  },
  {
    logo: nestleLogo,
    projectTitle: 'Des sols vivants pour des céréales pleines de vitalité',
    slug: 'des-sols-vivants-pour-des-cereales-pleines-de-vitalite',
    fullname: 'Alain Deketele',
    location: 'Remicourt, Marne',
    description:
      'Le projet d’Alain a pour objectif d’améliorer la santé des sols agricoles grâce à des pratiques agroécologiques. Cela permettra de cultiver des céréales de haute qualité tout en préservant la biodiversité et l’environnement.',
    video_url: 'https://vimeo.com/757510041',
    image: 'home-alain-deketele.jpg',
  },
  {
    logo: nestleLogo,
    projectTitle: 'Des fleurs sauvages et des haies à la Bergerie',
    slug: 'des-fleurs-sauvages-et-des-haies-a-la-bergerie-gaec-gosset',
    fullname: 'Martin, Jean et Timothée Gosset',
    location: 'Montloué, Hauts-de-France',
    description:
      'Ce projet consiste à planter des haies et des fleurs sauvages pour favoriser la biodiversité et améliorer l’écosystème autour de l’exploitation agricole. Ces plantations permettent de créer des habitats pour la faune locale, tout en préservant les sols et en encourageant une agriculture plus respectueuse de l’environnement.',
    video_url: 'https://vimeo.com/756783418',
    image: 'home-martin-gosset.jpg',
  },
  {
    logo: nestleLogo,
    projectTitle: 'Des céréales bio aux portes de Lyon',
    slug: 'des-cereales-bio-aux-portes-de-lyon',
    fullname: 'Sandrine Juffet',
    location: 'Lyon, Rhône',
    description:
      'Le projet de Sandrine vise à produire des céréales biologiques à proximité de Lyon, tout en respectant l’environnement. L’initiative met en place des pratiques agricoles durables et locales, favorisant la biodiversité et réduisant l’empreinte carbone liée à la production et au transport.',
    image: 'home-sandrine-juffet.jpg',
  },
  {
    logo: nestleLogo,
    projectTitle: 'Des céréales et des légumes respectueux de l’environnement !',
    slug: 'des-cereales-et-des-legumes-respectueux-de-l-environnement',
    fullname: 'Alexandre Merle',
    location: 'Serrières-en-Chautagne, Auvergne-Rhône-Alpes',
    description:
      'Ce projet vise à promouvoir une production agricole respectueuse de l’environnement, en combinant la culture de céréales et de légumes biologiques. L’initiative met l’accent sur des pratiques agroécologiques pour améliorer la biodiversité et la qualité des sols, tout en offrant des produits sains et durables pour les consommateurs.',
    image: 'home-alexandre-merle.jpg',
    video_url: 'https://vimeo.com/756788012',
  },
  {
    logo: nestleLogo,
    slug: 'soutenez-paul-un-jeune-agriculteur-en-conversion-bio',
    projectTitle: 'Soutenez Paul, un jeune agriculteur en conversion bio',
    fullname: 'Paul Keller',
    location: 'Francin, Auvergne-Rhône-Alpes',
    description:
      'Paul se lance dans la conversion de son exploitation céréalière en biologique. Il sollicite un soutien financier pour mener à bien ce projet, qui inclut notamment l’achat de matériel plus respectueux de l’environnement.',
    image: 'home-paul-keller.jpg',
    video_url: 'https://www.youtube.com/watch?v=jjQMfPgdra4',
  },
  {
    logo: nestleLogo,
    slug: 'de-bonnes-cereales-bio-respectueuses-de-l-environnement',
    projectTitle: 'De bonnes céréales bio respectueuses de l’environnement',
    fullname: 'Martial et Anthony Vial',
    location: 'Saint-Quentin-Fallavier, Auvergne-Rhône-Alpes',
    description:
      'Ce projet vise à produire des céréales biologiques respectueuses de l’environnement. Il s’inscrit dans une démarche de protection des sols et de la biodiversité tout en utilisant des pratiques agricoles durables. L’objectif est de garantir des produits sains tout en limitant l’impact écologique.',
    image: 'home-martial-vial.jpg',
    video_url: 'https://www.youtube.com/watch?v=vttMAyJtB-Q&t=2s',
  },
  {
    logo: nestleLogo,
    slug: 'conversion-en-bio-d-un-cerealier-du-nord',
    projectTitle: 'Conversion en bio d’un céréalier du Nord',
    fullname: 'Aymeric Ferté',
    location: 'Les Septvallons, Hauts-de-France',
    description:
      'Ce projet vise à revitaliser les sols agricoles par des pratiques agroécologiques, pour produire des céréales de qualité tout en préservant l’environnement, en acquérant une roto-étrille.',
    image: 'home-aymeric-ferte.jpeg',
  },
  {
    logo: nestleLogo,
    slug: 'creer-plus-de-biodiversite-dans-une-ferme-cerealiere',
    projectTitle: 'Créer plus de biodiversité dans une ferme céréalière',
    fullname: 'Margot Linard et Etienne Mouchet',
    location: 'Vineuil, Centre-Val de Loire',
    description:
      'Le projet vise à augmenter la biodiversité sur une ferme céréalière en plantant des haies, des arbres et en diversifiant les cultures pour créer des habitats favorables à la faune locale. L’objectif est de régénérer les sols tout en produisant de manière plus durable',
    image: 'home-margot-linard.jpeg',
  },
]
