import { Flex, Text, Button, Image, Box } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import { useMountedBreakpoints } from '@miimosa/design-system'
import { Right, Left, NestleLogo } from './icons'
import Slider from 'react-slick'
import React from 'react'

function Arrow({
  icon,
  left = 'none',
  right = 'none',
  onClick,
}: {
  icon: React.ReactNode
  left: string
  right: string
  onClick?: () => void
}) {
  return (
    <Flex
      position="absolute"
      left={left}
      right={right}
      onClick={onClick}
      bottom="46%"
      zIndex={10}
      borderRadius="full"
      backgroundColor="rgba(255,255,255,0.3)"
      h="40px"
      w="40px"
      justifyContent="center"
      alignItems="center"
      _hover={{ cursor: 'pointer' }}
    >
      {icon}
    </Flex>
  )
}

const Slide = ({
  bgImage,
  logo,
  text,
  link,
}: {
  bgImage: React.ReactNode
  logo: React.ReactNode
  text: React.ReactNode
  link: string
}) => {
  return (
    <Flex position="relative" h={{ base: '450px', md: '450px' }} bgSize="cover" w="full">
      {bgImage}
      <Flex
        w="full"
        h="full"
        zIndex={0}
        bgGradient={{
          lg: 'linear-gradient(to-r, rgba(244, 233, 226, 1) 30%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%)',
          md: 'linear-gradient(to-r, rgba(244, 233, 226, 1) 40%, rgba(255, 255, 255, 0.3) 100%)',
          base: 'linear-gradient(to-r, rgba(244, 233, 226, 0.6) 0%, rgba(244, 233, 226, .6) 100%)',
        }}
        pl={{ base: 4, md: 24, lg: 48 }}
        pr={{ base: 4, md: 24, lg: 0 }}
        alignItems="center"
      >
        <Flex
          direction="column"
          w={{ base: 'full', lg: '60%' }}
          h={'100%'}
          alignItems={{ base: 'center', lg: 'left' }}
          pt={{ base: 4, lg: 8 }}
          pb={{ base: 16, lg: 20 }}
          justifyContent="space-between"
        >
          <Box display="flex" h="150px" alignItems="center" justifyContent="center">
            {logo}
          </Box>
          <Flex h={{ base: '240px', md: 'auto' }} alignItems="center">
            <Text fontWeight="bold" textAlign="left" fontSize={{ base: 16, md: 20 }}>
              {text}
            </Text>
          </Flex>
          <OperationButton link={link} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export const OperationButton = ({ link }: { link: string }) => {
  return (
    <Button
      variant="brandPrimary"
      as={link ? 'a' : 'span'}
      {...(link ? { href: link } : {})}
      bgColor={link ? 'black' : 'gray'}
      borderRadius="10px"
      px={4}
      py={2}
      fontWeight="bold"
      fontSize={{ base: 20, md: 24 }}
      opacity={link ? 1 : 0.7}
    >
      {link ? 'Découvrir la démarche' : 'À venir'}
    </Button>
  )
}

export const Hero = () => {
  const isMobile = useMountedBreakpoints({ base: true, md: false }, false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow right="5%" icon={<Right />} left="none" />,
    prevArrow: <Arrow left="5%" icon={<Left />} right="none" />,
    arrows: !isMobile,
    appendDots: (dots: any) => (
      <Flex m={0} p={0} justifyContent="center" position="absolute" bottom={{ base: '20px', md: '30px' }}>
        {dots}
      </Flex>
    ),
    initialSlide: 1,
  }
  return (
    <Flex w="full" direction="column">
      <Slider {...settings}>
        <Slide
          bgImage={
            <Image
              src={s3ImageURL('home-nestle-hero.jpg')}
              position="absolute"
              top="0"
              left={{ base: '0', md: '10%' }}
              w="100%"
              h="100%"
              objectFit="cover"
              objectPosition={{ base: '50% 0', md: '0 20%' }}
              transform="scaleX(-1)"
              zIndex="-1"
            />
          }
          logo={<NestleLogo color="#297a37" w={{ base: '150px', md: '220px' }} h={'60px'} />}
          text={
            <>
              L’ambition de NESTLÉ® Céréales France : un approvisionnement en blé bio 100% français d’ici 2025. Pour
              cela, les équipes de NESTLÉ® Céréales travaillent avec la coopérative Oxyane pour développer une filière
              bio locale avec des agriculteurs en région Auvergne Rhône Alpes.
            </>
          }
          link="/nestle#demarche"
        />
        <Slide
          bgImage={
            <Image
              src={s3ImageURL('home-purina-hero.jpg')}
              position="absolute"
              top="0"
              left={{ base: '0', md: '10%' }}
              w="100%"
              h="100%"
              objectFit="cover"
              objectPosition={{ base: '50% 0', md: '0 20%' }}
              transform="scaleX(-1)"
              zIndex="-1"
            />
          }
          logo={
            <Image src={s3ImageURL('purina-logo-friskies.png')} width={{ base: '130px', md: '200px' }} height="auto" />
          }
          text={
            <>
              À partir de 22 novembre, FRISKIES® s’engage et participe au financement de 3 projets d’agriculture
              régénératrice avec une enveloppe totale de 30&nbsp;000€. Chaque projet agricole est assuré de recevoir
              5&nbsp;000€. Les 15 000€ restants sont répartis au prorata des votes en ligne entre les trois projets. À
              vous de voter&nbsp;!
            </>
          }
          link="/friskies#demarche"
        />
        <Slide
          bgImage={
            <Image
              src={s3ImageURL('home-ricore-hero.jpeg')}
              position="absolute"
              top="0"
              left={{ base: '0', md: '10%' }}
              w="100%"
              h="100%"
              objectFit="cover"
              objectPosition={{ base: '50% 0', md: '0 50%' }}
              transform="scaleX(-1)"
              zIndex="-1"
            />
          }
          logo={
            <Flex>
              <Image src={s3ImageURL('ricore-logo.png')} width={{ base: '150px', md: '220px' }} height="auto" />
            </Flex>
          }
          text={
            <>
              Du 14 octobre au 15 novembre, RICORÉ® soutient 3 producteurs laitiers de Challerange avec 20&nbsp;000 €
              pour financer leurs projets écologiques. Votre vote détermine comment répartir ces fonds entre eux:
              choisissez votre projet préféré et aidez nos agriculteurs à adopter des pratiques plus durables&nbsp;!
            </>
          }
          link="/ricore#demarche"
        />
      </Slider>
    </Flex>
  )
}
