export const projects = [
  {
    video: 'https://www.youtube.com/embed/GQnZHFyWO90?si=gr-GWzjWd11stHpS',
    title: 'Préserver les sols de notre exploitation',
    title2: 'Les jardins d’Aestiv',
    username: 'Méryl et Benoît',
    slug: 'un-cerealier-bio-dans-les-pays-de-l-ain',
    description:
      'Méryl et Benoît sont éleveurs de vaches de race Aubrac, producteurs de céréales et de légumes dans l’Ain, sur une ferme de 300 ha en agriculture biologique depuis 2016. Ils cultivent également des légumes secs et oléagineux transformés en partie en pâtes, en pain et en huile. Ils souhaitent investir dans une bineuse, outil de désherbage mécanique qui facilitera aussi l’aération du sol et favorisera la fertilisation des cultures.',
    image: 'jardin-daestiv.png',
    order: 0,
    place: 'Saint-Didier-d’Aussiat, Ain',
    anchor: 'meryl',
  },
  {
    video: 'https://www.youtube.com/embed/hy332Skg5Qk?si=5znqVOKNR3-vMINC',
    title: 'Une houe mécanique pour désherber de manière durable',
    title2: 'Bio la Balme',
    username: 'Guy et Sébastien',
    slug: 'maraichage-et-cereales-bio-en-savoie',
    description:
      'Guy et Sébastien sont agriculteurs depuis 1984, producteurs céréaliers et maraîchers en agriculture biologique depuis 2013. Ils cultivent blé, tournesol, soja et aussi pommes de terre, courges et tomates sur 33ha, avec l’introduction de couverts végétaux dans une approche agroécologique. Ils souhaitent aujourd’hui investir dans une houe rotative, un nouvel outil plus performant pour le désherbage mécanique de leurs terres cultivées sans pesticides.',
    image: 'bio-la-balme.png',
    place: 'La Balme, Savoie',
    order: 1,
    anchor: 'guy',
  },
]
